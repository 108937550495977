





























import { Component, Vue } from 'vue-property-decorator';
import VButton from '@/components/VButton.vue';
import { Action } from 'vuex-class';
import * as types from '@/store/account/types';
import SmallCard from '@/components/cards/SmallCard.vue';
import emailValidator from '@/utilities/validators/email';

@Component({
  components: { VButton, SmallCard },
})
export default class ForgotPassword extends Vue {

  @Action('forgotPassword', { namespace: 'account' }) private forgotPassword!: types.ForgotPasswordAction;

  private email: string = '';
  private emailDirty: boolean = false;
  private submitting: boolean = false;
  private completed: boolean = false;
  private error: boolean = false;

  get emailValidator(): boolean | null {
    return emailValidator(this.email, this.emailDirty);
  }

  private submit() {
    this.emailDirty = true;
    if (this.emailValidator !== false) {
      this.submitting = true;
      this.forgotPassword(this.email).then(() => {
        this.error = false;
      }).catch(() => {
        this.error = true;
      }).finally(() => {
        this.submitting = false;
        this.completed = true;
      });
    }
  }
}
